import { Builder } from '@builder.io/react'
import NostoRecommendations from '../nosto/NostoRecommendations'

export type NostoComponentProps = {
  addToCart?: boolean
  deal?: boolean
  id?: string
  layout: 'horizontal' | 'vertical'
}

export default function NostoComponent({ addToCart, deal, id, layout }: NostoComponentProps) {
  return id ? (
    <NostoRecommendations addToCart={addToCart} placement={id} deal={deal} layout={layout} />
  ) : null
}

export function RegisterNosto() {
  Builder.registerComponent(NostoComponent, {
    name: 'Nosto Component',
    inputs: [
      {
        name: 'id',
        friendlyName: 'Nosto Div ID',
        helperText: 'Examples: frontpage-nosto-3 or categorypage-nosto-1',
        type: 'string',
      },
      {
        name: 'pageType',
        friendlyName: 'Nosto Page Type',
        type: 'text',
        enum: ['front-page', 'landing-page', 'category-page'],
      },
      {
        name: 'deal',
        friendlyName: 'Turn on Deal Badges',
        helperText: 'This turns deal badges on for all products in this recommendations.',
        type: 'boolean',
      },
      {
        name: 'addToCart',
        friendlyName: 'Show Add to Cart Button',
        helperText:
          'This turns on or off the ability to show the "Add to Cart" button for these recommendations.',
        type: 'boolean',
      },
      {
        name: 'layout',
        friendlyName: 'Nosto Layout',
        helperText:
          'This determines whether the nosto reccomendations will be placed in a single horizontal scrollable widget or be place within a responsive vertical grid (mobile grid: 2 recs per row capped at 8 recs OR desktop: 6 recs per row capped at 12 recs).',
        type: 'text',
        enum: ['horizontal', 'vertical'],
      },
    ],
  })
}
