import { Builder, BuilderComponent } from '@builder.io/react'
import type { BuilderContent } from '@builder.io/sdk'
import '@builder.io/widgets'
import { RegisterCountdownTimer } from '../../global/builder/CountdownTimer'
import { RegisterNosto } from '../../global/builder/nosto'
import css from './BuilderPage.module.css'

RegisterNosto()
RegisterCountdownTimer()

export default function BuilderPage({
  props,
}: {
  props: { content?: BuilderContent; model?: string; data?: any }
}) {
  if (!Builder.isEditing && !Builder.isPreviewing && !props.content) {
    return <div>not found</div>
  }
  return (
    <>
      {props.content ? (
        <div className={css.BuilderContainer}>
          <BuilderComponent
            content={props.content}
            model={props.model || 'page'}
            data={props.data}
          />
        </div>
      ) : (
        <div>not found</div>
      )}
    </>
  )
}
