import { Builder } from '@builder.io/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MONTHS } from '../../../lib/utils/date'

export type ExpirationDate = {
  expirationDay: number
  expirationHour: number
  expirationMinute: number
  expirationMonth: string
  expirationSecond: number
  expirationYear: number
}

export type CountdownTimerProps = {
  title: string
  titleColor: string
} & ExpirationDate

function calculateTimeLeft({
  expirationDay,
  expirationHour,
  expirationMinute,
  expirationMonth,
  expirationSecond,
  expirationYear,
}: ExpirationDate) {
  const localExpirationDate = new Date(
    expirationYear,
    MONTHS.findIndex((month) => month === expirationMonth),
    expirationDay,
    expirationHour,
    expirationMinute,
    expirationSecond
  ).getTime()
  const localCurrentTime = new Date().getTime()
  const timeLeft = localExpirationDate - localCurrentTime

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
    .toString()
    .padStart(2, '0')
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, '0')

  const hasExpired = timeLeft <= 0

  return {
    days: hasExpired ? '00' : days,
    hours: hasExpired ? '00' : hours,
    minutes: hasExpired ? '00' : minutes,
    seconds: hasExpired ? '00' : seconds,
    hasExpired,
  }
}

export function CountdownTimer({
  expirationDay = 1,
  expirationHour = 0,
  expirationMinute = 0,
  expirationMonth = MONTHS[0] as string,
  expirationSecond = 0,
  expirationYear = new Date().getFullYear() + 1,
  title,
  titleColor,
}: CountdownTimerProps) {
  const countdownTimerRef = useRef<NodeJS.Timeout | null>(null)

  const [countdownDate, setCountdownDate] = useState({
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    hasExpired: false,
  })

  const updateCountdownTime = useCallback(() => {
    const timeLeft = calculateTimeLeft({
      expirationDay,
      expirationHour,
      expirationMinute,
      expirationMonth,
      expirationSecond,
      expirationYear,
    })

    if (timeLeft.hasExpired && countdownTimerRef.current) {
      window.clearInterval(countdownTimerRef.current)
    }

    setCountdownDate(timeLeft)
  }, [
    expirationDay,
    expirationHour,
    expirationMinute,
    expirationMonth,
    expirationSecond,
    expirationYear,
  ])

  useEffect(() => {
    countdownTimerRef.current = setInterval(updateCountdownTime, 1000)

    return () => {
      if (countdownTimerRef.current) {
        window.clearInterval(countdownTimerRef.current)
      }
    }
  }, [updateCountdownTime])

  return (
    <div className="grid grid-cols-1 gap-8 rounded-lg font-sans lg:grid-cols-2">
      <div className="grid-cols-1 px-4 pt-8 lg:p-8">
        <div className="text-center lg:text-left" style={{ color: titleColor }}>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
      <div className="grid-cols-1 px-4 pb-8 lg:p-8">
        <div className="flex items-center justify-center space-x-6 text-black lg:justify-end">
          {['days', 'hours', 'minutes', 'seconds'].map((prop) => (
            <div key={prop} className="flex flex-col text-center">
              <div className="m-auto flex h-12 w-12 items-center justify-center rounded-md bg-white md:h-20 md:w-20">
                <p className="text-3xl font-semibold md:text-4xl">{countdownDate[prop]}</p>
              </div>
              <p className="text-xs font-semibold uppercase text-white md:text-base">{prop}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function RegisterCountdownTimer() {
  Builder.registerComponent(CountdownTimer, {
    name: 'Countdown Timer',
    inputs: [
      {
        name: 'title',
        friendlyName: 'Title',
        helperText: 'The text that is placed to the left/above the countdown timer.',
        type: 'richText',
        required: true,
      },
      {
        name: 'titleColor',
        friendlyName: 'Title Color',
        type: 'color',
        defaultValue: '#fff',
        required: false,
      },
      {
        name: 'expirationMonth',
        friendlyName: 'Expiration Month',
        helperText: 'The future month that will be counted down to (January-December).',
        type: 'text',
        enum: MONTHS,
        defaultValue: MONTHS[0],
        required: true,
      },
      {
        name: 'expirationDay',
        friendlyName: 'Expiration Day',
        helperText: 'The future day that will be counted down to (1-31).',
        type: 'number',
        defaultValue: 1,
        min: 1,
        max: 31,
        required: true,
      },
      {
        name: 'expirationYear',
        friendlyName: 'Expiration Year',
        helperText: 'The future year that will be counted down to represented as 4 digits (2022).',
        type: 'number',
        defaultValue: new Date().getFullYear() + 1,
        required: true,
      },
      {
        name: 'expirationHour',
        friendlyName: 'Expiration Hour',
        helperText: 'The future hour that will be counted down to (0-23).',
        type: 'number',
        defaultValue: 0,
        min: 0,
        max: 23,
        required: true,
      },
      {
        name: 'expirationMinute',
        friendlyName: 'Expiration Minute',
        helperText: 'The future minute that will be counted down to (0-59).',
        type: 'number',
        defaultValue: 0,
        min: 0,
        max: 59,
        required: true,
      },
      {
        name: 'expirationSecond',
        friendlyName: 'Expiration Second',
        helperText: 'The future second that will be counted down to (0-59).',
        type: 'number',
        defaultValue: 0,
        min: 0,
        max: 59,
        required: true,
      },
    ],
  })
}
